/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/lato/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
    url('./assets/fonts/lato/lato-v16-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/lato/lato-v16-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/lato/lato-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/lato/lato-v16-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/lato/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/lato/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
    url('./assets/fonts/lato/lato-v16-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/lato/lato-v16-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/lato/lato-v16-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/lato/lato-v16-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/lato/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/lato/lato-v16-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
    url('./assets/fonts/lato/lato-v16-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/lato/lato-v16-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/lato/lato-v16-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/lato/lato-v16-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/lato/lato-v16-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

html,
body {
  font-family: 'Lato', sans-serif;
  min-height: 100%;
  height: 100%;
}

#root {
  display: flex;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}
